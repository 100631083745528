<template>
  <div class="flex flex-col gap-4 sm:flex-row sm:items-center">
    <div class="flex flex-col gap-x-4 gap-y-2 sm:flex-row sm:items-center">
      <div class="flex-grow md:flex md:items-center md:space-x-2">
        <div class="date-picker inline space-x-2">
          <inline-svg src="/img/icons/calendar.svg"></inline-svg>
          <DateTimePicker
            data-cy="start-date-input"
            @input="isTimeChanged = true"
            v-model="from"
            :valid="isTimeValid()"
            :max="to"
            :min="minTime"
          />
        </div>
      </div>
      <div>to</div>
      <div class="flex-grow md:flex md:items-center md:space-x-2">
        <div class="date-picker inline space-x-2">
          <inline-svg src="/img/icons/calendar.svg"></inline-svg>
          <DateTimePicker
            data-cy="end-date-input"
            @input="isTimeChanged = true"
            v-model="to"
            :valid="isTimeValid()"
            :min="from"
          />
        </div>
      </div>
    </div>
    <new-button
      class="self-start"
      data-cy="date-time-filter-button"
      v-if="isTimeChanged"
      :disabled="!isTimeValid()"
      @click="getPosts()"
    >
      Filter
    </new-button>
  </div>
</template>
<script>
  import DateTimePicker from './DateTimePicker';
  import moment from 'moment';

  export default {
    name: 'history-date-time-filter',
    data() {
      return {
        minTime: null,
        from: null,
        to: null,
        isTimeChanged: false,
      };
    },
    props: {
      isCategoryPage: {
        type: Boolean,
        default: false,
      },
      isEvergreenQueue: {
        type: Boolean,
        default: false,
      },
    },
    components: {
      DateTimePicker,
    },
    mounted() {
      this.$emit('updateTimeRange', null);
    },
    created() {
      this.setTime();
    },
    methods: {
      setTime() {
        this.minTime = moment('2006-01-01').format('YYYY-MM-DDTHH:mm');
        this.from = moment().subtract(1, 'year').hours(0).minutes(0).format('YYYY-MM-DDTHH:mm');
        this.to = moment().format('YYYY-MM-DDTHH:mm');
        this.isTimeChanged = true;
      },
      isTimeValid() {
        if (this.from === 'Invalid date' || this.to === 'Invalid date') return true;
        return moment(this.from).isBefore(moment(this.to));
      },
      getPosts() {
        if (this.isCategoryPage) {
          this.$eventStore.recurrentPosts('Manage Categories: View posts: Filter');
        } else if (this.isEvergreenQueue) {
          this.$eventStore.evergreenQueue('Filter');
        } else {
          this.$eventStore.history('Filter');
        }
        this.$emit('updateTimeRange', {
          from: this.from,
          to: this.to,
        });
        this.isTimeChanged = false;
      },
    },
  };
</script>
