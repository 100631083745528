<template>
  <div>
    <base-dropdown
      menu-fixed
      class="nav-item"
      tag="div"
      title-tag="button"
      title-classes="nav-link pr-0"
    >
      <new-button
        data-cy="filter-button"
        @click.prevent="handleFilterDropDownButton"
        slot="title-container"
        type="secondary"
      >
        <inline-svg src="/img/icons/new/filter.svg" class="stroke-ds-button-icon" />
        <div class="hidden items-center gap-1 sm:flex">
          <span v-if="selectedFilter === 'all'">More Filters</span>
          <span v-else-if="selectedFilter === 'deleted'">Deleted Posts</span>
          <span v-else-if="selectedFilter === 'hypefuryTweets'">Hypefury Posts</span>
          <span v-else-if="selectedFilter === 'disabled'">Disabled Posts</span>
          <span v-else-if="selectedFilter === 'retweets'">Sorted by Retweets</span>
          <span v-else-if="selectedFilter === 'likes'">Sorted by Likes</span>
          <span v-else-if="selectedFilter === 'impressions'">Sorted by Impressions</span>
          <span v-else-if="selectedFilter === 'replies'">Sorted by Replies</span>
          <span v-else-if="selectedFilter === 'bookmarks'">Sorted by Bookmarks</span>
          <span v-else-if="selectedFilter === 'profileClicks'">Sorted by Profile Clicks</span>
          <span v-else>Search</span>
        </div>
      </new-button>
      <template>
        <base-button
          data-cy="filter-all-posts"
          @click="setFilter('all')"
          class="new-dropdown-item dark-mode-dropdown-item"
          :class="selectedFilter === 'all' ? 'active' : ''"
        >
          <inline-svg src="/img/icons/all.svg" />
          <span>All Posts</span>
        </base-button>
        <base-button
          data-cy="filter-deleted-posts"
          v-if="canFilterDeletedPosts"
          @click="setFilter('deleted')"
          class="new-dropdown-item dark-mode-dropdown-item"
          :class="selectedFilter === 'deleted' ? 'active' : ''"
        >
          <inline-svg src="/img/icons/delete.svg" />
          <span>Deleted Posts</span>
        </base-button>
        <base-button
          data-cy="filter-hypefury-posts"
          @click="setFilter('hypefuryTweets')"
          class="new-dropdown-item dark-mode-dropdown-item"
          :class="selectedFilter === 'hypefuryTweets' ? 'active' : ''"
        >
          <inline-svg src="/img/icons/hypefury.svg" />
          <span>Hypefury Posts</span>
        </base-button>
        <base-button
          v-if="isCategoryPage"
          @click="setFilter('disabled')"
          class="new-dropdown-item dark-mode-dropdown-item"
          :class="selectedFilter === 'disabled' ? 'active' : ''"
        >
          <inline-svg src="/img/icons/close.svg" />
          <span>Disabled Posts</span>
        </base-button>
        <hr />
        <base-button
          data-cy="filter-sort-retweets"
          @click="setFilter('retweets')"
          class="new-dropdown-item dark-mode-dropdown-item"
          :class="selectedFilter === 'retweets' ? 'active' : ''"
        >
          <inline-svg src="/img/icons/new-retweet.svg" />
          <inline-svg
            v-if="isUserPlanStandardOrBelow(this.userProfile.customerStatus)"
            src="/img/icons/premium.svg"
          /><span>Sort by Retweets</span>
        </base-button>
        <base-button
          data-cy="filter-sort-likes"
          @click="setFilter('likes')"
          class="new-dropdown-item dark-mode-dropdown-item"
          :class="selectedFilter === 'likes' ? 'active' : ''"
        >
          <inline-svg src="/img/icons/like.svg" />
          <inline-svg
            v-if="isUserPlanStandardOrBelow(this.userProfile.customerStatus)"
            src="/img/icons/premium.svg"
          /><span>Sort by Likes</span>
        </base-button>
        <base-button
          data-cy="filter-sort-impressions"
          @click="setFilter('impressions')"
          class="new-dropdown-item dark-mode-dropdown-item"
          :class="selectedFilter === 'impressions' ? 'active' : ''"
        >
          <inline-svg src="/img/icons/impressions.svg" />
          <inline-svg
            v-if="isUserPlanStandardOrBelow(this.userProfile.customerStatus)"
            src="/img/icons/premium.svg"
          />
          <span>Sort by Impressions</span>
        </base-button>
        <base-button
          data-cy="filter-sort-replies"
          @click="setFilter('replies')"
          class="new-dropdown-item dark-mode-dropdown-item"
          :class="selectedFilter === 'replies' ? 'active' : ''"
        >
          <inline-svg src="/img/icons/reply.svg" />
          <inline-svg
            v-if="isUserPlanStandardOrBelow(this.userProfile.customerStatus)"
            src="/img/icons/premium.svg"
          />
          <span>Sort by Replies</span>
        </base-button>
        <base-button
          data-cy="filter-sort-bookmarks"
          @click="setFilter('bookmarks')"
          class="new-dropdown-item dark-mode-dropdown-item"
          :class="selectedFilter === 'bookmarks' ? 'active' : ''"
        >
          <inline-svg src="/img/icons/new/bookmark.svg" />
          <inline-svg
            v-if="!['trial', 'premium'].includes(this.userProfile.customerStatus)"
            src="/img/icons/premium.svg"
          />
          <span>Sort by Bookmarks</span>
        </base-button>
        <base-button
          data-cy="filter-sort-profile-clicks"
          @click="setFilter('profileClicks')"
          class="new-dropdown-item dark-mode-dropdown-item"
          :class="selectedFilter === 'profileClicks' ? 'active' : ''"
        >
          <inline-svg src="/img/icons/profile_clicks.svg" />
          <inline-svg
            v-if="isUserPlanStandardOrBelow(this.userProfile.customerStatus)"
            src="/img/icons/premium.svg"
          />
          <span>Sort by Profile Clicks</span>
        </base-button>
      </template>
    </base-dropdown>
    <upgrade-to-premium-pop-up
      :show="showUpgradeToPremiumPopUp"
      :content="`Upgrade to the ${getPlanLabel(
        'premium'
      )} plan and unlock search filters and ton of other powerful features!`"
      @continue-operation="continuePremiumFeature"
      @close="showUpgradeToPremiumPopUp = false"
    />
  </div>
</template>
<script>
  import { mapGetters } from 'vuex';
  import CustomerStatusMixin from '@/views/Mixins/CustomerStatusMixin';

  export default {
    name: 'history-filter',
    mixins: [CustomerStatusMixin],
    props: {
      canFilterDeletedPosts: {
        type: Boolean,
        default: true,
      },
      isCategoryPage: {
        type: Boolean,
        default: false,
      },
      isEvergreenQueue: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        premiumFeatureOnHold: '',
        selectedFilter: 'all',
        showUpgradeToPremiumPopUp: false,
      };
    },
    computed: {
      ...mapGetters({ userProfile: 'getUserProfile' }),
    },
    methods: {
      continuePremiumFeature() {
        this.setFilter(this.premiumFeatureOnHold);
      },
      setFilter(filter) {
        if (
          this.isUserPlanStandardOrBelow(this.userProfile.customerStatus) &&
          ['likes', 'retweets', 'impressions', 'replies', 'bookmarks', 'profileClicks'].includes(
            filter
          )
        ) {
          this.premiumFeatureOnHold = filter;
          this.showUpgradeToPremiumPopUp = true;
        } else {
          this.selectedFilter = filter;
          this.$emit('filterChange', filter);
        }
      },
      handleFilterDropDownButton() {
        if (this.isCategoryPage) {
          this.$eventStore.recurrentPosts('Manage Categories: View posts: More filters');
        } else if (this.isEvergreenQueue) {
          this.$eventStore.evergreenQueue('More filters');
        } else {
          this.$eventStore.history('More filters');
        }
      }
    },
    mixins: [CustomerStatusMixin],
  };
</script>
