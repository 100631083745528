<template>
  <div>
    <div class="loader" v-if="!firstBatchOfPostsLoaded">
      <span data-cy="history-loading" class="loading loading-lg">
        <inline-svg src="/img/icons/loading.svg"></inline-svg>
      </span>
    </div>
    <div>
      <div>
        <h1 class="mb-6" v-if="!categoryId">History</h1>
        <div
          class="sm:flex-no-wrap mb-6 flex flex-wrap items-center justify-between"
          v-else-if="categoryId === 'non-categorized'"
        >
          <div class="title flex items-center gap-5 overflow-hidden pr-3">
            <router-link to="/recurrent-posts/categories">
              <inline-svg src="/img/icons/back.svg" />
            </router-link>
            <h2>Non-categorized</h2>
          </div>
        </div>
        <div class="sm:flex-no-wrap mb-6 flex flex-wrap items-center justify-between" v-else>
          <div class="title flex items-center gap-5 overflow-hidden pr-3">
            <router-link to="/recurrent-posts/categories">
              <inline-svg src="/img/icons/back.svg" />
            </router-link>
            <h2 v-if="activeCategory">
              {{ activeCategory.name }}
            </h2>
          </div>
          <div class="actions flex items-center space-x-2">
            <new-button @click="addTweetToCategory(activeCategory)" data-cy="new-post-button">
              New Post
            </new-button>
          </div>
        </div>
        <div v-if="threads" class="space-y-8">
          <base-alert
            type="warning"
            radius="rounded-2xl"
            v-if="isUserPlanBasicOrBelow(userProfile.customerStatus)"
            data-cy="history-subscription-alert"
          >
            You are currently on the
            {{ userProfile.customerStatus === 'none' ? 'free' : userProfile.customerStatus }} plan.
            Your history display is limited.<br />
            <router-link data-cy="history-upgrade-link" to="/billing" class="text-main-color-100"
              >Grab a {{ getPlanLabel('standard') }} Hypefury subscription</router-link
            >
            to unlock your full history and see your tweets' statistics (engagement, profile clicks,
            etc.)
          </base-alert>
          <div v-else-if="categoryId !== 'non-categorized'" class="mb-6 flex flex-col">
            <div class="flex flex-row space-x-3">
              <history-search-field @input="updateSearchInput" @filterChange="updateFilter" />
              <history-filters
                v-if="!searchInput || !searchInput.keyword"
                @filterChange="updateFilter"
                :isCategoryPage="Boolean(categoryId)"
              />
            </div>
            <div class="space-y-4 md:flex md:justify-between md:space-y-0">
              <history-date-time-filter
                v-if="['all', 'hypefuryTweets', 'disabled'].includes(filter)"
                @updateTimeRange="updateTimeRange"
                :isCategoryPage="Boolean(categoryId)"
              />
            </div>
          </div>

          <base-alert
            type="info"
            class="mb-6"
            v-if="categoryId && categoryId !== 'non-categorized'"
          >
            <p class="text-ds-text-primary">
              Recurrent posts are ordered and will be posted from top to bottom.
            </p>
            <p class="text-ds-text-primary">
              You can reorder them by dragging and dropping one post over another.
            </p>
          </base-alert>

          <template v-if="activeCategory">
            <div class="flex flex-wrap items-center gap-x-6 gap-y-3 sm:justify-between">
              <div class="flex items-center text-muted">
                <inline-svg src="/img/icons/info.svg" />
                <div>You are viewing posts in the {{ activeCategory.name }} category</div>
              </div>
              <div class="flex gap-4">
                <tooltip content="Shuffle order" v-if="threads && threads.length > 1">
                  <new-button
                    @click="
                      shufflePosts();
                      $eventStore.recurrentPosts('Manage Categories: View posts: Shuffle order');
                    "
                  >
                    <inline-svg src="/img/icons/refresh.svg" class="stroke-current" />
                  </new-button>
                </tooltip>
                <new-button
                  @click="
                    showAutoplugModal();
                    $eventStore.recurrentPosts('Manage Categories: View posts: Category autoplugs');
                  "
                >
                  Autoplugs
                </new-button>

                <new-drop-down buttonType="bordered" icon data-cy="queue-menu">
                  <div class="flex flex-col items-start justify-center gap-y-1">
                    <new-button
                      v-for="option in options"
                      :key="option.dataCy"
                      :data-cy="option.dataCy"
                      type="dropdown"
                      @click="option.onClick"
                    >
                      <inline-svg :class="`h-5 w-5 stroke-current`" :src="option.icon" />
                      <span>{{ option.text }}</span>
                    </new-button>
                  </div>
                </new-drop-down>
              </div>
            </div>
          </template>
          <div
            class="mb-6 flex items-center space-x-3 text-muted"
            v-if="categoryId === 'non-categorized'"
          >
            <inline-svg src="/img/icons/info.svg" />
            <div>You are viewing the recurrent posts with no categories assigned.</div>
          </div>
          <div class="space-y-6" v-if="threads.length === 0 && firstBatchOfPostsLoaded">
            <ul data-cy="history-empty-results" class="ml-4 list-disc pb-2">
              <li v-if="filter === 'deleted'">No posts have been deleted.</li>
              <li v-else-if="filter.startsWith('deleted')">No results found.</li>
              <li v-else-if="!categoryId">Your history is empty.</li>
              <li v-else-if="categoryId">
                <span v-if="categoryId === 'non-categorized'">No non-categorized posts.</span>
                <span v-else>No posts in this category.</span>
              </li>
            </ul>
          </div>
          <virtual-list
            :data-key="'id'"
            :data-sources="getThreadsData()"
            :data-component="itemComponent"
            :extra-props="extraProps"
            :page-mode="true"
            :item-class="'pb-6'"
            :estimate-size="174"
            :keeps="10"
            @dragged-thread-updated="
              (thread) => {
                draggedThread = thread;
              }
            "
            @dropped-non-empty-slot="dropNonEmptySlot"
            @show-replace-recurrent-post-modal="showReplaceRecurrentPostModal"
          />
        </div>
        <back-to-top-custom />
        <autoplug-modal
          :show="isAutoplugModalVisible"
          @close="closeAutoplugModal"
          :category="activeCategory"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import dao from '@/dao';
  import lodash from 'lodash';
  import { mapGetters } from 'vuex';
  import HistoryFilters from '@/components/HistoryFilters';
  import HistorySearchField from '@/components/HistorySearchField';
  import HistoryDateTimeFilter from '@/components/HistoryDateTimeFilter';
  import HistoryMixin from '@/views/Mixins/HistoryMixin';
  import CreateCategoryPostMixin from './Mixins/CreateCategoryPostMixin';
  import BackToTopCustom from '@/views/Widgets/BackToTopCustom';
  import { scrollToElement } from '../util/scrollToElement';
  import VirtualList from 'vue-virtual-scroll-list';
  import HistoryItem from '@/components/HistoryItem';
  import { buildCategoryFromFirestore } from '../util/buildCategoryFromFirestore';
  import AutoplugModal from '@/views/Modals/AutoplugModal';
  import GlobalSettingsMixin from '@/views/Mixins/GlobalSettingsMixin.vue';
  import { EventBus } from '@/event-bus';
  import SwalModalMixin from './Mixins/SwalModalMixin.vue';
  import CustomerStatusMixin from './Mixins/CustomerStatusMixin.vue';
  import controller from '@/controller';

  const fb = require('../firebase');

  export default {
    name: 'History',
    props: {
      categoryId: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        options: [
          {
            dataCy: 'enable-threads-cross-posting',
            icon: '/img/icons/new/socials/threads.svg',
            text: 'Enable Threads cross-posting for all category posts',
            onClick: this.enableThreadsCrosspostingForAllCategoryPosts,
          },
          {
            dataCy: 'disable-threads-cross-posting',
            icon: '/img/icons/new/socials/threads.svg',
            text: 'Disable Threads cross-posting for all category posts',
            onClick: this.disableThreadsCrosspostingForAllCategoryPosts,
          },
        ],
        isEditModalVisible: false,
        loadingMorePosts: false,
        postsFromCursors: {
          all: [],
          deleted: [],
          retweets: [],
          likes: [],
          impressions: [],
          replies: [],
          bookmarks: [],
          profileClicks: [],
          hypefuryTweets: [],
          disabled: [],
        },
        threadToEdit: null,
        itemComponent: HistoryItem,
        activeCategory: null,
        recurrentPostsMetrics: {},
        selectedRecurrentPost: null,
        draggedThread: null,
        unsubscribeToCategoryListener: null,
      };
    },
    mounted() {
      if (this.isUserPlanStandardOrAbove(this.userProfile.customerStatus)) {
        this.onScroll();
      }
      this.$eventStore.visitHistoryPage();
    },
    computed: {
      ...mapGetters({
        currentUser: 'getCurrentUser',
        userProfile: 'getUserProfile',
        parentUserProfile: 'getParentUserProfile',
      }),
      extraProps() {
        return {
          categoryId: this.categoryId,
          recurrentPostsMetrics: this.recurrentPostsMetrics,
          draggedPost: this.draggedThread,
          threadsLength: this.threadsLength,
        };
      },
      threadsLength() {
        return this.getThreadsData().length;
      },
    },
    components: {
      BackToTopCustom,
      HistoryFilters,
      HistorySearchField,
      HistoryDateTimeFilter,
      VirtualList,
      AutoplugModal,
    },
    methods: {
      async enableThreadsCrosspostingForAllCategoryPosts() {
        this.swalModal({
          title: 'Enable Threads cross-posting',
          text: 'Are you sure you want to enable Threads cross-posting for all category posts?',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          preConfirm: async () => {
            try {
              await controller.enableThreadsCrosspostingForAllCategoryPosts(
                this.currentUser,
                this.userProfile,
                this.categoryId,
              );
              this.$notify({
                type: 'success',
                message: 'Enabled Threads cross-posting for all category posts',
              });
            } catch (error) {
              this.swalModal({
                title: 'Sorry',
                text: 'An error has occurred while enabling Threads cross-posting for all category posts',
                type: 'warning',
              });
            }
          },
        });
      },
      async disableThreadsCrosspostingForAllCategoryPosts() {
        this.swalModal({
          title: 'Disable Threads cross-posting',
          text: 'Are you sure you want to disable Threads cross-posting for all category posts?',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          preConfirm: async () => {
            try {
              await controller.disableThreadsCrosspostingForAllCategoryPosts(
                this.currentUser,
                this.userProfile,
                this.categoryId,
              );
              this.$notify({
                type: 'success',
                message: 'Disabled Threads cross-posting for all category posts',
              });
            } catch (error) {
              this.swalModal({
                title: 'Sorry',
                text: 'An error has occurred while disabling Threads cross-posting for all category posts',
                type: 'warning',
              });
            }
          },
        });
      },
      getThreadsData() {
        if (!this.threads) {
          return [];
        }
        const historyThreads = this.threads.map((thread) => {
          delete thread.user;
          thread.text = '';
          if (thread.originalTweetInfo && thread.originalTweetInfo.text) {
            thread.text = thread.originalTweetInfo.text;
          }
          return thread;
        });

        const orderedPostsIds = lodash
          .get(this.activeCategory, 'postsOrder', [])
          .map((postRef) => postRef.id);

        return this.activeCategory
          ? historyThreads
              .filter((t) => orderedPostsIds.indexOf(t.id) >= 0)
              .sort((a, b) => {
                const aIndex = orderedPostsIds.indexOf(a.id);
                const bIndex = orderedPostsIds.indexOf(b.id);
                return aIndex - bIndex;
              })
          : historyThreads;
      },
      showReplaceRecurrentPostModal(thread) {
        EventBus.$emit('open-composer', null, false, null, false, true, this.categoryId, thread);
      },
      async dropNonEmptySlot(slot) {
        try {
          await dao.categories.swapPostsOrder(this.categoryId, slot.id, this.draggedThread.id);
          this.$notify({
            type: 'success',
            message: 'Posts swapped successfully!',
          });
        } catch (error) {
          this.swalModal({
            title: 'Error',
            text: `An error has occurred while trying to swap posts' order.`,
            type: 'error',
          });
          console.error('error', error);
        }
      },

      closeEditModal: function () {
        this.isEditModalVisible = false;
        this.isEditingCategories = false;
        this.threadToEdit = null;
      },
      editThread(thread) {
        this.threadToEdit = thread;
        this.isEditModalVisible = true;
      },
      createPostsQuery(cursorCount = 0) {
        if (this.categoryId) {
          if (this.activeCategory) {
            const queries = dao.posts.getPostsQueriesForHistoryCategory(
              this.userProfile.uid,
              this.categoryId,
              this.timeRange,
              this.activeCategory.postsOrder.slice(cursorCount * 10, (cursorCount + 1) * 10),
            );
            lodash.mapValues(this.searchQueries, (value, key) => {
              queries[key] = value;
            });
            return queries;
          } else {
            const queries = dao.posts.getPostsQueriesForHistoryCategory(
              this.userProfile.uid,
              this.categoryId,
              this.timeRange,
              null,
            );
            lodash.mapValues(this.searchQueries, (value, key) => {
              queries[key] = value;
            });
            return queries;
          }
        } else {
          const queries = dao.posts.getPostsQueriesForHistory(this.userProfile.uid, this.timeRange);
          lodash.mapValues(this.searchQueries, (value, key) => {
            queries[key] = value;
          });
          return queries;
        }
      },
      scrollToHighlightedThreadId: function () {
        // Note: this will not work if
        // the post is too far back in the history
        const highlightedThreadId = this.$route.params.threadId;
        if (!highlightedThreadId) return;

        this.$nextTick(() => {
          const element = this.$refs[highlightedThreadId];
          if (!element || !element[0]) return;
          scrollToElement(element[0]);
          element[0].classList.add('shadow-highlight', 'rounded-lg');
        });
      },
      shouldTweetPublishStatusBeDisplayed(thread) {
        return (
          thread.tweetIds.length !== thread.tweets.length &&
          !thread.publishingError &&
          thread.delayBetweenTweets
        );
      },
      setRecurrentPostsMetrics: lodash.debounce(async function (threadsPromises) {
        const clones = await Promise.all(threadsPromises);
        const clonesDocs = clones
          .flatMap((clone) => clone.docs)
          .map((doc) => ({ ...doc.data().tweets[0], id: doc.data().clonedFromPost.id }));
        const groupedClones = lodash.groupBy(clonesDocs, 'id');
        const threadMetrics = {};
        Object.entries(groupedClones).forEach(([threadId, clones]) => {
          const thread = this.threads.find((thread) => thread.id === threadId);

          const numberOfThreadsWithMetrics = [thread.tweets[0], ...clones].filter(
            (tweet) => tweet.impressions
          ).length;

          if (numberOfThreadsWithMetrics <= 1) {
            return;
          }

          const totalMetrics = [thread.tweets[0], ...clones]
            .filter((t) => t.impressions >= 0)
            .reduce(
              (acc, thread) => {
                acc.impressions += thread.impressions;
                acc.favoriteCount += thread.favoriteCount;
                acc.retweetCount += thread.retweetCount;
                acc.replyCount += thread.replyCount;
                acc.quoteCount += thread.quoteCount;
                acc.bookmarkCount += thread.bookmarkCount;
                acc.userProfileClicks += thread.userProfileClicks;
                return acc;
              },
              {
                impressions: 0,
                favoriteCount: 0,
                retweetCount: 0,
                replyCount: 0,
                quoteCount: 0,
                bookmarkCount: 0,
                userProfileClicks: 0,
              }
            );

          const averageMetrics = {
            impressions: totalMetrics.impressions / numberOfThreadsWithMetrics,
            favoriteCount: totalMetrics.favoriteCount / numberOfThreadsWithMetrics,
            retweetCount: totalMetrics.retweetCount / numberOfThreadsWithMetrics,
            replyCount: totalMetrics.replyCount / numberOfThreadsWithMetrics,
            quoteCount: totalMetrics.quoteCount / numberOfThreadsWithMetrics,
            bookmarkCount: totalMetrics.bookmarkCount / numberOfThreadsWithMetrics,
            userProfileClicks: totalMetrics.userProfileClicks / numberOfThreadsWithMetrics,
          };

          threadMetrics[threadId] = {
            average: averageMetrics,
            total: totalMetrics,
          };
        });

        this.recurrentPostsMetrics = threadMetrics;
      }, 2000),
      async shufflePosts() {
        const self = this;
        try {
          self.swalModal({
            title: `Shuffle posts' order`,
            html: `Are you sure you want to shuffle the posts' order?`,
            type: 'warning',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonText: 'Yes, go ahead',
            preConfirm: async () => {
              await dao.categories.updatePostsOrder(
                self.categoryId,
                lodash.shuffle(self.activeCategory.postsOrder)
              );
            },
          });
        } catch (error) {
          this.swalModal({
            title: 'Error',
            text: `An error has occurred while trying to shuffle posts' order.`,
            type: 'error',
          });
          console.error('error', error);
        }
      },
    },
    mixins: [
      HistoryMixin,
      CreateCategoryPostMixin,
      GlobalSettingsMixin,
      SwalModalMixin,
      CustomerStatusMixin,
    ],
    watch: {
      filter(val) {
        if (!val.startsWith('search')) {
          this.searchQueries = {};
        }
      },
      threads(newVal, oldVal) {
        if (this.categoryId && newVal.length > oldVal.length) {
          const threadsToFetch = newVal.filter(
            (thread) => thread.lastClonePostedTime && !newVal[thread.id]
          );
          const threadsPromises = threadsToFetch.map((thread) =>
            dao.posts.getPostClones(thread.id)
          );

          this.setRecurrentPostsMetrics(threadsPromises);
        }

        const firstLoad = lodash.isEmpty(oldVal) && !lodash.isEmpty(newVal);
        if (firstLoad) {
          this.scrollToHighlightedThreadId();
        }
      },
      categoryId: {
        immediate: true,
        handler: function (newVal) {
          this.resetTimeRange();
          this.unsubscribeToCategoryListener && this.unsubscribeToCategoryListener();

          if (newVal && newVal !== 'non-categorized') {
            this.unsubscribeToCategoryListener = fb.categoriesCollection
              .doc(newVal)
              .onSnapshot((categoryDoc) => {
                if (categoryDoc.exists) {
                  this.activeCategory = buildCategoryFromFirestore(categoryDoc);
                  this.processFilterChange('all');
                  this.onScroll();
                }
              });
            return;
          }

          this.activeCategory = null;

          if (this.filter) {
            this.resetPostFromCursors();
            this.processFilterChange(this.filter);
          }
        },
      },
    },
  };
</script>

<style scoped>
  .timeline-one-side {
    max-width: 100%;
  }
  .timeline-one-side .timeline-content {
    max-width: 100%;
  }
  .analytics-separator {
    border-top: 1px solid rgba(0, 0, 0, 0.04);
  }
  .analytics-btn:hover {
    text-decoration: none;
    box-shadow: none;
    transform: none;
  }
  .favorite-btn {
    text-decoration: none;
    box-shadow: none;
    transform: none;
  }
  .favorite-btn:hover {
    text-decoration: none;
    box-shadow: none;
    transform: none;
  }
  .retweet-badge {
    position: absolute;
    top: 1em;
    right: 1em;
  }
  .retweet-container {
    position: relative;
  }
</style>
