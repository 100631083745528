<template>
  <div class="flex flex-grow items-center mb-3" :class="{ 'show-search': isSearchToggled }">
    <base-input
      data-cy="search-input"
      placeholder="Search…"
      input-group-classes="border border-input-border focus-within:border-secondary"
      input-classes="form-control-sm simple"
      :prepend-icon="'/img/icons/search.svg'"
      :append-icon="searchInput ? '/img/icons/close.svg' : ''"
      :append-icon-click-event-handler="clearSearch"
      v-model="searchInput"
      @append-icon-click="searchInput = ''"
    >
    </base-input>
  </div>
</template>
<script>
  export default {
    name: 'history-search-field',
    data() {
      return {
        isSearchToggled: false,
        searchInput: '',
      };
    },
    props: {
      isFavorite: {
        type: Boolean,
        default: null,
      },
    },
    methods: {
      clearSearch() {
        this.$emit('filterChange', 'all');
        this.searchInput = '';
      },
      toggleSearch() {
        this.isSearchToggled = !this.isSearchToggled;
      },
    },
    watch: {
      searchInput(keyword) {
        this.$emit('input', { keyword, isFavorite: this.isFavorite });
      },
    },
  };
</script>