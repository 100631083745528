<template>
  <base-input
    v-if="isDateTimeLocalSupported()"
    type="datetime-local"
    :data-cy="dataCy"
    v-model="selectedDate"
    :max="max"
    :min="min"
    :valid="valid"
    v-on="listeners"
    :name="name"
  />
  <div :class="classes" class="text-align-center" v-else>
    <select :value="selectedDay" @input="updateDay($event)">
      <option v-for="day in allDaysInMonth" :key="day" :value="day">
        {{ day }}
      </option>
    </select>
    <label>/</label>
    <select :value="selectedMonth" @input="updateMonth($event)">
      <option v-for="month in months" :key="month">
        {{ month }}
      </option>
    </select>
    <label>/</label>
    <select :value="selectedYear" @input="updateYear($event)">
      <option v-for="year in years" :key="year">
        {{ year }}
      </option>
    </select>
    <select :value="selectedHour" @input="updateHour($event)">
      <option v-for="hour in hours" :key="hour">
        {{ hour }}
      </option>
    </select>
    <label>:</label>
    <select :value="selectedMinute" @input="updateMinute($event)">
      <option v-for="(minute, index) in 60" :key="minute">
        {{ index | formatMinute }}
      </option>
    </select>
  </div>
</template>

<script>
  import moment from 'moment';
  export default {
    name: 'DateTimePicker',
    data() {
      return {
        selectedDate: this.value,
        selectedYear: moment(this.value).format('YYYY'),
        selectedMonth: moment(this.value).format('MMM'),
        selectedDay: moment(this.value).format('D'),
        selectedMinute: moment(this.value).format('mm'),
        selectedHour: moment(this.value).format('HH'),
      };
    },
    filters: {
      formatMinute: function (minute) {
        return minute.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
      },
    },
    watch: {
      selectedDate: function (newVal) {
        this.$emit('input', newVal);
      },
      value: function (newVal) {
        this.selectedYear = moment(newVal).format('YYYY');
        this.selectedMonth = moment(newVal).format('MMM');
        this.selectedDay = moment(newVal).format('D');
        this.selectedMinute = moment(newVal).format('mm');
        this.selectedHour = moment(newVal).format('HH');
        this.selectedDate = newVal;
      },
      selectedMonth: function () {
        this.populateDays();
      },
      selectedYear: function () {
        this.populateDays();
      },
    },
    computed: {
      years: function () {
        const currentDateSelected = moment(new Date()).add(1, 'year');
        return Array.from(
          { length: 100 },
          (value, index) => Number(currentDateSelected.format('YYYY')) - index
        );
      },
      allDaysInMonth: function () {
        const selectedDate = `${this.selectedYear}-${this.selectedMonth}`;
        const currentDateSelected = moment(selectedDate, 'YYYY-MMM');
        const currentMonthDates = Array.from(
          { length: currentDateSelected.daysInMonth() },
          (val, index) => moment(selectedDate, 'YYYY-MMM').startOf('month').add(index, 'days')
        );

        return currentMonthDates.map((day) => day.format('D'));
      },
      listeners() {
        return this.$listeners;
      },
    },
    updated() {
      const newSelectedTime = `${this.selectedYear}-${this.selectedMonth}-${this.selectedDay}-${this.selectedMinute}-${this.selectedHour}`;
      const newSelectedTimeWellFormed = moment(newSelectedTime, 'YYYY-MMM-D-mm-HH').format(
        'YYYY-MM-DDTHH:mm'
      );
      this.$emit('input', newSelectedTimeWellFormed);
    },
    created: function () {
      this.months = moment.monthsShort();
      this.hours = [
        '00',
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
        '21',
        '22',
        '23',
      ];
    },
    props: {
      value: {
        type: String,
      },
      valid: {
        type: Boolean,
      },
      dataCy: {
        type: String,
      },
      name: {
        type: String,
      },
      max: {
        type: String,
      },
      min: {
        type: String,
      },
      classes: {
        type: String,
      },
    },
    methods: {
      populateDays() {
        const selectedMonth = this.selectedMonth;
        const selectedYear = this.selectedYear;
        const selectedDay = this.selectedDay;
        this.selectedDay = moment(
          `${selectedYear}-${selectedMonth}-${selectedDay}`,
          'YYYY-MMM-D'
        ).format('D');

        let i = 1;

        /**
         * When the browser does not support datetime-local input we use fallback select inputs.
         * Because of that, if user selects the wrong day for a given month/year we need to correct the day.
         * Say the user selects January 29 and then changes the month to February in a non leap year,
         * we need to update the day to be 28.
         */
        if (
          !this.isDateTimeLocalSupported() &&
          selectedMonth !== 'Invalid date' &&
          selectedYear !== 'Invalid date'
        ) {
          while (this.selectedDay === 'Invalid date') {
            this.selectedDay = moment(
              `${selectedYear}-${selectedMonth}-${selectedDay - i}`,
              'YYYY-MMM-D'
            ).format('D');
            i++;
          }
        }
      },
      isDateTimeLocalSupported: function () {
        const input = document.createElement('input');
        input.type = 'datetime-local';

        return input.type === 'datetime-local';
      },
      updateDay(evt) {
        this.selectedDay = evt.target.value;
      },
      updateMonth(evt) {
        this.selectedMonth = evt.target.value;
      },
      updateYear(evt) {
        this.selectedYear = evt.target.value;
      },
      updateMinute(evt) {
        this.selectedMinute = evt.target.value;
      },
      updateHour(evt) {
        this.selectedHour = evt.target.value;
      },
    },
  };
</script>

<style scoped>
  .text-align-center {
    text-align-last: center;
  }
</style>
