<template>
  <modal
    :show="show"
    @close="close"
    class="schedule-retweet-modal"
    :class="[{ 'overlay--on': submitting || !isWholeScheduleLoaded }]"
  >
    <div
      data-cy="schedule-once-overlay"
      class="overlay"
      v-if="submitting || !isWholeScheduleLoaded"
    >
      <div class="loader">
        <span class="loading loading-lg"
          ><inline-svg src="/img/icons/loading.svg"></inline-svg
        ></span>
      </div>
    </div>

    <div data-cy="schedule-once-header" slot="header" class="modal-title">
      Schedule retweet once
    </div>
    <div class="space-y-6" v-if="schedule">
      <virtual-list
        style="height: 500px; overflow-y: auto"
        :data-key="'midnight'"
        :data-sources="timeSlots"
        :data-component="itemComponent"
        class="virtual-scroller"
        @was-submitted="slotWasScheduled"
        @is-submitting="submitting = true"
        :keeps="10"
        :extra-props="itemProps"
      />
    </div>
  </modal>
</template>
<script>
  import moment from 'moment';
  import 'moment-timezone';
  import { mapGetters, mapState } from 'vuex';
  import ThreadMixin from '../Mixins/ThreadMixin';
  import ScheduleSlotItem from '@/components/ScheduleSlotItem';
  import VirtualList from 'vue-virtual-scroll-list';

  export default {
    name: 'schedule-retweet-modal',
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
      ...mapState(['schedule', 'isWholeScheduleLoaded']),
      timeSlots() {
        const slots = this.schedule.getTimeSlotsByNonEmptyDate();
        return Object.keys(slots).map((midnight) => {
          let daySlot = {};
          daySlot.timeSlots = slots[midnight];
          daySlot.midnight = midnight;
          return daySlot;
        });
      },
    },
    data() {
      return this.initialState();
    },
    filters: {
      formatNameOfDay: function (time, timezone) {
        const midnightOfToday = moment.tz(timezone).startOf('day');
        const midnightOfTime = moment.tz(time, timezone).startOf('day');

        if (midnightOfToday.diff(midnightOfTime, 'day') === 0) return 'Today';
        if (midnightOfToday.diff(midnightOfTime, 'day') === -1) return 'Tomorrow';
        return midnightOfTime.format('dddd');
      },
      formatDateOfDay: function (time, timezone) {
        const momentTime = moment.tz(time, timezone);
        const format = momentTime.year() === moment().year() ? 'MMMM DD' : 'LL';
        return momentTime.format(format);
      },
    },
    methods: {
      close() {
        this.$emit('close');
      },
      initialState() {
        return {
          hoveredSlot: null,
          time: null,
          submitting: false,
          itemComponent: ScheduleSlotItem,
          itemProps: {},
        };
      },
      now: function () {
        return this.formatTimeForInput(moment().add(10, 'minute').startOf('minute'));
      },
      slotWasScheduled(success) {
        this.submitting = false;
        if (success) {
          this.close();
          this.resetModal();
        }
      },
      resetModal() {
        Object.assign(this.$data, this.initialState());
      },
      setTime: function () {
        this.time = this.now();
      },
    },
    mixins: [ThreadMixin],
    mounted() {
      this.setTime();
    },
    props: {
      show: Boolean,
      post: {
        type: Object,
      },
    },
    watch: {
      show: function (show) {
        if (!show) return;
        this.setTime();
      },
      post: {
        immediate: true,
        handler: function (newVal) {
          if (newVal) {
            this.itemProps = { action: 'retweet', post: newVal };
          }
        },
      },
    },
    components: {
      VirtualList,
    },
  };
</script>
<style>
  @supports (padding: max(0px)) {
    form {
      padding-bottom: env(safe-area-inset-bottom) !important;
    }
  }
  .schedule-retweet-modal .modal-body {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }
  .schedule-retweet-modal .virtual-scroller {
    padding-top: 1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
</style>
